/* Modals */
.swal2-popup {
  color: black !important;
}
.swal2-html-container {
  font-weight: 600 !important;
}
.modal-btn-close-video,
.modal-btn-close {
  background-image: url(../assets/close.svg);
  background-repeat: no-repeat;
}

.modal-btn-close-button {
  background-image: url(../assets/close-button.svg);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 20px;
  right: -60px;
}
.modal-btn-close-video {
  margin-right: -7px !important;
}

.modal-btn-close-video,
.modal-btn-close:hover {
  background-image: url(../assets/close.svg);
  background-repeat: no-repeat;
}

.modal-btn-close-video,
.modal-btn-close:focus {
}
.modal-btn-close.white {
  background-image: url(../assets/close-white.svg) !important;
}

/* PDF modal */
.modal-pdf-container {
  width: 80% !important;
  height: 85vh !important;
  padding-top: 2em !important;
  border-radius: 0px !important;
  overflow: hidden !important;
}

.modal-pdf-html {
  padding-top: 1em !important;
  margin: 0 !important;
}

/* Video modal */

.modal-video-container {
  padding-top: 1em !important;
  width: 90% !important;
  border-radius: 0px !important;
}

.modal-video-html {
  padding-top: 2em !important;
  padding-left: 1em !important;
  padding-right: 1em !important;
  margin: 0 !important;
}

.modal-papel-container {
  width: fit-content !important;
  height: fit-content;
  padding: 2rem !important;
  border-radius: 0 !important;
}

/* Text modal */
.modal-text-container {
  width: 90% !important;
  height: 90%;
  max-height: 700px;
  padding-top: 2em !important;
  padding-bottom: 4em !important;
  border-radius: 10px !important;
  background-color: #05656c !important;
}

.modal-text-title {
  padding: 0 0 0.5em !important;
  text-align: center !important;
  color: white !important;
  font-size: 22px !important;
  letter-spacing: 1px !important;
}

.modal-text-html {
  padding: 0.5em 3em 0.5em !important;
  margin: 0 !important;
  text-align: justify !important;
  line-height: inherit !important;
  color: white !important;
  font-size: 18px !important;
  letter-spacing: 0.8px !important;
  height: 100%;
}

.modal-wait-container {
  border-radius: 0px !important;
  height: 60%;
  width: 55% !important;
  padding: 2em 2.5em 2em 2em !important;
}

/* Confirm modal */

.modal-confirm-container {
  height: fit-content;
  width: 50% !important;
  padding: 2em !important;
  border-radius: 10px !important;
  color: black !important;
}

.modal-confirm-title {
  color: #05656c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  font-style: normal !important;
  letter-spacing: 0, 61px !important;
}

/*Question explanation modal */
.modal-question-explanation {
  border-radius: 10px !important;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1) !important;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  min-width: 60% !important;
  max-width: 90% !important;
  overflow-x: hidden !important;
  color: black;
}

.modal-caso-clinico-resolution {
  border-radius: 0px !important;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1) !important;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  width: 80% !important;
  overflow-x: hidden !important;
  color: black;
}

.modal-caso-clinico-html {
  padding: 0.5em 1.5em 0.5em !important;
  line-height: 16px !important;
  font-size: 18px !important;
  letter-spacing: 0px !important;
}

.modal-enunciado-container {
  width: 90% !important;
  height: 90%;
  max-height: 700px;
  padding-top: 2em !important;
  border-radius: 10px !important;
}

.modal-start-game-small,
.modal-start-game {
  width: 70% !important;
  height: fit-content;
  max-height: 700px;
  padding-top: 2em !important;
  border-radius: 10px !important;
}

.modal-start-game-small {
  height: 60%;
}

.modal-enunciado-html {
  padding: 0.5em 3em !important;
  margin: 0 !important;
  text-align: justify !important;
  line-height: inherit !important;
  color: black !important;
  font-size: 18px !important;
  letter-spacing: 0.8px !important;
  height: 100%;
}

.modal-start-game-html {
  padding: 0.5em 3em !important;
  margin: 0 !important;
  text-align: justify !important;
  line-height: inherit !important;
  color: black !important;
  font-size: 18px !important;
  letter-spacing: 0.8px !important;
  height: 100%;
}

.modal-historial {
  width: 70% !important;
  height: 95vh !important;
  border-radius: 10px !important;
}

.modal-image {
  padding: 0 !important;
  margin: 0 !important;
  width: fit-content !important;
  height: 95vh !important;
}
.modal-image-html {
  padding: 0 !important;
  margin: 0 !important;
}

.bullet-grey li::marker {
  color: #d9d9d9 !important;
}
.bullet-black li::marker {
  color: #000000 !important;
}

.modal-question-info {
  width: 70% !important;
  height: 95%;
  max-height: 700px;
  padding-top: 2em !important;
  border-radius: 10px !important;
}

.modal-question-info-html {
  padding: 0.5em 3em !important;
  margin: 0 !important;
  margin-bottom: -5% !important;
  text-align: justify !important;
  line-height: inherit !important;
  color: black !important;
  font-size: 18px !important;
  letter-spacing: 0.8px !important;
  height: 100%;
}

.modal-ficha-tecnica-container {
  width: 90% !important;
  height: 90%;
  max-height: 700px;
  padding-top: 2em !important;
  padding-bottom: 4em !important;
  border-radius: 10px !important;
  background-color: #05656c !important;
  overflow: hidden !important;
}

.modal-ficha-tecnica-html {
  padding: 0.5em 3em 0.5em !important;
  margin: 0 !important;
  text-align: justify !important;
  line-height: inherit !important;
  color: black !important;
  font-size: 18px !important;
  letter-spacing: 0.8px !important;
  height: 100%;
  overflow: hidden !important;
}
