@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap");

/* source-sans-pro-regular - latin */
@font-face {
  font-family: "Source Sans Pro Regular";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/source-sans-pro/source-sans-pro-v21-latin-regular.woff2")
      format("woff2"),
    url("./fonts/source-sans-pro/source-sans-pro-v21-latin-regular.woff")
      format("woff");
}

/* source-sans-pro-700 - latin */
@font-face {
  font-family: "Source Sans Pro Bold";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/source-sans-pro/source-sans-pro-v21-latin-700.woff2")
      format("woff2"),
    url("./fonts/source-sans-pro/source-sans-pro-v21-latin-700.woff")
      format("woff");
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

/* source-sans-pro-regular - latin */
@font-face {
  font-family: "PoppinsRegular";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/poppins/Poppins-Regular.woff2") format("woff2"),
    url("./fonts/poppins/Poppins-Regular.woff") format("woff");
}

/* source-sans-pro-700 - latin */
@font-face {
  font-family: "PoppinsBold";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/poppins/Poppins-Bold.woff2") format("woff2"),
    url("./fonts/poppins/Poppins-Bold.woff") format("woff");
}

@font-face {
  font-family: "CourierNewBold";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/courier/CourierNewPS-BoldMT.woff2") format("woff2"),
    url("./fonts/courier/CourierNewPS-BoldMT.woff") format("woff");
}

@font-face {
  font-family: "ChalkboardRegular";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/chalkboard/ChalkboardSE-Light.woff2") format("woff2"),
    url("./fonts/chalkboard/ChalkboardSE-Light.woff") format("woff");
}

body {
  font-family: "Source Sans Pro" !important;
  height: 100vh;
  overflow: hidden;
  font-size: 14px;
  background-color: #05656c;
  width: 100.1%;
  line-height: 25px;
  letter-spacing: 1px;
  font-weight: 600 !important;
}
h1 {
}
.App {
  height: 100vh;
  color: black;
  overflow-x: hidden;
  overflow-y: hidden;
}
.splash-screen {
  height: 100vh;
  width: 100vw;
  background-color: #eaf1d6;
  background-image: url(./assets/logo-medical.png);
  background-position: center center;
  background-repeat: no-repeat;
}
.startbg {
  background-image: url(./assets/bghome.png);
  background-position: bottom center 100%;
  height: 100% !important;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
}
.legalGebro {
  bottom: 25px;
  color: white;
}
.line-height-30 {
  line-height: 30px !important;
}
.line-height-40 {
  line-height: 40px !important;
}
.txt8 {
  font-size: 8px !important;
  line-height: 10px !important;
}
.txt9 {
  font-size: 9px !important;
  line-height: 15px !important;
}
.txt10 {
  font-size: 10px !important;
  line-height: 10px !important;
}
.txt12 {
  font-size: 12px !important;
}
.txt13 {
  font-size: 13px !important;
}
.txt14 {
  font-size: 14px !important;
}
.txt16 {
  font-size: 16px !important;
}
.txt17 {
  font-size: 17px !important;
}
.txt18 {
  font-size: 18px !important;
}
.txt20 {
  font-size: 20px !important;
}
.txt21 {
  font-size: 21px !important;
}
.txt22 {
  font-size: 22px !important;
}
.txt24 {
  font-size: 24px !important;
}
.txt25 {
  font-size: 25px !important;
}
.txt26 {
  font-size: 26px !important;
}
.txt28 {
  font-size: 28px !important;
}
.txt30 {
  font-size: 30px !important;
}
.txt32 {
  font-size: 32px !important;
}
.txt42 {
  font-size: 42px !important;
}
.txt-courier {
  font-family: "Courier New" !important;
}
.txt-courier-bold {
  font-family: CourierNewBold !important;
}
.txt-purple {
  color: #593090 !important;
}
.txt-pink {
  color: #ff7f6b !important;
}
.txt-turquoise {
  color: #05656c !important;
}
.txt-dark-green {
  color: #04656b !important;
}
.txt-light-green {
  color: #00ada6 !important;
}
.txt-green {
  color: #87ca1b !important;
}
.txt-orange {
  color: #ec7700 !important;
}
.txt-red {
  color: #df2231 !important;
}
.txt-brown {
  color: rgba(102, 102, 102, 1) !important;
}
.txt-grey {
  color: #9f9c9c !important;
}
.txt-black {
  color: black !important;
}
.txt-yellow {
  color: #d6d431 !important;
}
.txt-yellow-light {
  color: #eaf886 !important;
}
.txt-white {
  color: white !important;
}
.txtBlue {
  color: rgba(27, 58, 148, 1) !important;
}
.txtWhite {
  color: #fff !important;
}
.bgBlue {
  opacity: 1;
  background-image: radial-gradient(
    rgb(174, 186, 207) 0%,
    rgba(126, 156, 209, 1) 100%
  );
}
.bg-blue-plain {
  background-color: #a2bcde !important;
}
.bg-prueba {
  background-color: #00ada6 !important;
  background-position: bottom center;

  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  height: calc(100vh - 91px) !important;
  width: 100vw !important;
  padding-top: 4vh;
}
.bg-turquoise {
  background-color: #05656c !important;
}
.bg-red {
  background-color: #ff7f6b !important;
  padding-top: 4vh;
}
.bg-black {
  background-color: black !important;
}
.bg-pink {
  background-color: #ffb3ab !important;
}

/* button */

.btn-home {
  cursor: pointer;
  text-align: center;
  padding: 0.5rem;
  margin: auto;
}

.text-btn-main {
  margin-top: 2px;
}

.btn-main {
  border-radius: 25px !important;
  border: none !important;
  opacity: 1;
  background-color: #ff7f6b !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  letter-spacing: 1px;
  font-family: "Source Sans Pro Bold" !important;
  text-decoration: none !important;
  height: 50px;
  width: fit-content;
  min-width: 168px;
  cursor: pointer;
}

.btn-main-cancel-white,
.btn-main-cancel {
  border-radius: 25px !important;
  border: 2.2px solid #ff7f6b !important;
  opacity: 1;
  background-color: transparent !important;
  color: #ff7f6b !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 50px;
  opacity: 1;
  font-style: normal;
  letter-spacing: 0, 88px;
}

.btn-main-cancel-white {
  border-color: #ffffff !important;
  color: #ffffff !important;
}

.border-blue {
  border: 2.2px solid #1b3a94 !important;
}

.txt-blue {
  color: #1b3a94 !important;
}

/* top */
.roundBtn {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  color: #05656c;
  background-color: #ffb3ab;
  cursor: pointer;
}

.container-score {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #00ada6;
  height: 40px;
  color: #eaf886;
  letter-spacing: 2px;
}
.timer {
  background-color: #00ada6;
  border-radius: 8px;
  padding: 5px;
  color: white !important;
  height: fit-content !important;
  width: 36px !important;
  font-family: "Source Sans Pro Bold" !important;
  font-size: 20px !important;
  letter-spacing: 1, 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.timer-big {
  border-radius: 12.952381px;
  opacity: 1;
  background-color: rgba(112, 157, 212, 1);
  padding: 5px;
  color: white !important;
  height: 60px !important;
  width: 60px !important;
  font-size: 32px !important;
  letter-spacing: 1, 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
  color: #ffffff !important;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

/* Scenario Bar */
.scenarioBar {
  position: fixed;
  bottom: 0px;
  height: 110px;
  width: 100%;
  z-index: 500;
  line-height: 16px;
}
.scenarioBar.active {
  bottom: 0px;
  transition: bottom 0.5s ease 0s;
}
.scenarioBar.inactive {
  bottom: -92px !important;
  transition: bottom 0.5s ease 0s;
}
.hide-container {
  border-radius: 5px;
  background-color: #ffb3ab;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scenarioBox {
  background-color: white;
  border-radius: 6px;
  height: 74px;
  font-size: 16px;
  line-height: 18px;
  position: relative;
  color: #1b3a94;
}
.scenarioIndicator {
  position: absolute;
  top: -5px;
  width: auto !important;
  right: 0px;
  padding-right: 5px !important;
  font-size: 13px;
  color: white;
}
.lock {
  position: absolute;
  top: 4px;
  right: 10px;
}
.completed-tab {
  position: absolute;
  top: 0px;
  right: 10px;
}

/* Register */
.fotoButton {
  position: relative;
  margin: 0;
  border: 2px dashed white;
  border-radius: 50%;
  height: 250px;
  width: 250px;
  background-color: #72748d;
  opacity: 0.85;
}
.image-upload > input {
  display: none;
}
.icofoto {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.icofoto-selected {
  object-fit: cover;
  border-radius: 50%;
  height: 248px !important;
  width: 250px !important;
}
.title1 {
  color: #ffffff;
  font-size: 18px;
}
.btn-add {
  position: absolute;
  right: 3px;
  top: 3px;
  bottom: 3px;
  margin: auto;
  margin-right: 10px;
  width: 30px;
  height: 30px;
}
.btn-remove {
  margin-right: 18px;
  width: 25px;
  height: 25px;
}
.input-add {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 100px;
  padding: 10px 100px 10px 20px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
}
.container-add {
  position: relative;
}

input,
select {
  border: 2px solid #ffffff !important;
  border-radius: 5px !important;
  height: 55px !important;
  background-color: transparent !important;
  color: white !important;
}
.bgBlue {
  background-color: #24284f;
}

.video-player {
  width: 100% !important;
  bottom: 50px;
  position: relative;
}
.video-playerB {
  width: 100% !important;
}
.bold {
  font-family: "Source Sans Pro Bold" !important;
}
.line-spacing-small {
  line-height: 100% !important;
}
.normal {
}
.light {
}
.regular {
  font-family: "Source Sans Pro Regular" !important;
}
.swal2-close:focus {
  box-shadow: none !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.title8 {
  font-family: "Source Sans Pro Bold" !important;
  font-size: 20px;
  letter-spacing: 4px;
}
.title8B {
  font-size: 20px;
  line-height: 18px;
}
.url {
  opacity: 0.6;
  color: rgba(80, 80, 80, 1);
  font-size: 12px;
  letter-spacing: 0px;
  text-align: center;
  padding-top: 23px;
}
input[type="checkbox"] {
  width: 3.5vh !important;
  height: 3.5vh !important;
  margin-top: 3px;
  accent-color: rgba(209, 89, 243, 1);
  border: 3px solid rgba(209, 89, 243, 1) !important;
  color: white !important;
}

.correct {
  accent-color: #87ca1b !important;
}
.incorrect {
  accent-color: #df2231 !important;
}

.bg-correct {
  background-color: #d6d431 !important;
  color: black;
  border-radius: 5px;
}
.bg-incorrect {
  background-color: #ff5948 !important;
  color: black;
  border-radius: 5px;
}
.bg-yellow {
  background-color: #eaf886 !important;
}

.active {
  z-index: 1000;
}
.active img {
  max-width: 120%;
  margin-top: -5%;
  margin-left: -5%;
  border: 0;
  padding: 0;
}
.active .number {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 16.5em;
  left: 1em;
  width: 41px;
  height: 41px;
  border-radius: 50%;
  background-color: #f4e72a;
}
.img-number {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f4e72a;
}
.z-indez-100 {
  z-index: 100;
}

/* intranet */
.bgIntranet {
  background-image: url("assets/navegador.svg");
  background-repeat: no-repeat;
  background-size: 95% auto;
  background-position: top;
}
.nav-link {
  font-family: "Source Sans Pro Bold" !important;
  background-color: rgba(27, 58, 148, 1) !important;
  font-size: 16px;
  color: white !important;
  text-decoration: none !important;
  border: none !important;
  margin-right: 5px !important;
  cursor: pointer;
  height: 53.5px;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  text-align: center;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.nav-link.active {
  background-color: #e9e9e9 !important;
  color: #1b3a94 !important;
  border: none !important;
}
.nav-link.correct {
  background-color: #87ca1b !important;
  border: none !important;
}
.nav-link.incorrect {
  background-color: #df2231 !important;
  border: none !important;
}
.bg-tab-content {
  border-radius: 0px 0px 14px 14px;
  opacity: 1;
  background-color: rgba(235, 235, 235, 1);
}

.trophy-icon {
}

/* Results */

.teamfoto {
  position: absolute;
  border-radius: 50%;
  height: 153px;
  width: 153px;
  left: 7.5%;
  top: 7.5%;
}
.teamfoto img {
  border-radius: 50%;
  object-fit: cover;
  height: 173px;
  width: 173px;
}
.separator {
  width: 100%;
  background-color: rgba(40, 60, 144, 1);
  height: 1px;
}

.teamfoto-small {
  position: absolute;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  left: 60px;
  top: 9px;
}
.teamfoto-small img {
  border-radius: 50%;
  object-fit: cover;
  height: 82px;
  width: 82px;
}

.figure-line {
  background-color: #690ed1;
  width: 1.5px;
  height: 20px;
}

/* Game over */
.bggameover {
  background-image: url("assets/bggameover.png");
  background-repeat: no-repeat;
  background-size: 103%;
  background-position: bottom -20px center;
}

.form-control::placeholder {
  color: black !important;
  opacity: 1;
}

.table-select {
  background-image: url("./assets/register/flecha-dropdown.svg");
  background-repeat: no-repeat;
  background-position: 95% center;
  cursor: pointer;
}

.word-select {
  background-image: url("./assets/register/flecha-dropdown.svg");
  background-repeat: no-repeat;
  background-position: 95% center;
  cursor: pointer;
  width: 100px !important;
  height: 40px !important;
}

.select-wrapper {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -5px;
  margin-left: 5px;
}

.custom-select {
  position: relative;
}

.custom-select select {
  background-image: url("./assets/flecha-dropdown-text.svg");
  background-repeat: no-repeat;
  background-position: 100% center;
  background-position-y: 70%;
  border-radius: 0%;
  border: none !important;
  border-bottom: 1px dashed #d159f3 !important;
  background-color: transparent !important;
  width: max-content !important;
  padding-left: 4px !important;
  padding-bottom: 0px !important;
  padding-right: 20px;
  height: 30px !important;
  outline: none;
  cursor: pointer;
}

.bg-internas {
  background-image: url("./assets/bg-internas.png");
  background-position: bottom center;
  height: 100%;

  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.bg-internas2 {
  background-image: url("./assets/bg-internas2.png");
  background-position: bottom center;
  height: 100%;

  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.oval-scenario-container.active,
.oval-scenario-container {
  width: 30vh;
  height: 30vh;
  background-color: #d0dfef;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.oval-scenario-container.active {
  background: linear-gradient(90deg, #66629e 0%, #278ea6 100%);
}

.oval-scenario,
.oval-scenario.passed,
.oval-scenario.active {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: linear-gradient(90deg, #518fcc 0%, #b14feb 100%);
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color: #2b3d90;
}

.oval-scenario.passed {
  background: #1ba591;
  color: #00a891;
}

.oval-scenario.active {
  background: linear-gradient(90deg, #66629e 0%, #278ea6 100%);
  border: 2.904605px solid white !important;
  color: white;
  cursor: pointer;
}

.oval-scenario-inner.passed,
.oval-scenario-inner.active,
.oval-scenario-inner {
  width: 97%;
  height: 97%;
  border-radius: 50%;
  background-color: #d0dfef;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.oval-scenario-inner.passed {
  background-color: #d0dfef;
}
.oval-scenario-inner.active {
  background: linear-gradient(90deg, #66629e 0%, #278ea6 100%);
}

.bg-active-scenario-bar {
  color: white;
  border-radius: 6px;
  box-shadow: 0px 6px 7px rgba(0, 0, 0, 0.208616);
  opacity: 1;
  background-color: rgba(156, 104, 166, 1);
}

.bg-inactive-scenario-bar {
  color: white;
  border-radius: 6px;
  opacity: 1;
  background-color: rgba(156, 104, 166, 1);
}

.bg-preguntas-3 {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  height: 100%;
}

.select-option-rectangle.selected,
.select-option-rectangle {
  border-radius: 4px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  width: 45vw;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  letter-spacing: 0px;
  line-height: 20px;
}
.select-option-rectangle.selected {
  box-shadow: 8px 8px 10px 0px #00000040 !important;
}
.option-selected {
  box-shadow: 8px 8px 10px 0px #00000040 !important;
}

.select-option-number-container {
  border-radius: 4px;
  opacity: 1;
  background-color: rgba(211, 212, 220, 1);
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-option-mas-info {
  position: absolute;
  bottom: 4px;
  right: 10px;
}

.select-option-text {
  border-radius: 4px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 12px;
  height: 8.7vh;
  line-height: 20px !important;
}

.select-option {
  border-radius: 4px;
  opacity: 1;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.200912);
  background-color: rgba(229, 224, 224, 1);
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-option.selected {
  background-color: rgba(85, 39, 208, 1);
}

.cursor-pointer {
  cursor: pointer;
}

.mt-6 {
  margin-top: 6rem;
}

.w-fit-content {
  width: fit-content !important;
}
.h-fit-content {
  height: fit-content !important;
}

.drag-drop-header {
  opacity: 1;
  background-color: rgba(209, 89, 243, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6vh;
}

.drag-drop-header-option {
  opacity: 1;
  background-color: rgba(245, 245, 245, 1);
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: left;
  width: 100%;
  height: 6vh;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  line-height: 20px !important;
}

.drag-drop-target {
  opacity: 0.287644;
  background-color: rgba(245, 245, 245, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6vh;
  text-align: center;
}

.drag-drop-option {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 6vh;
  cursor: grab !important;
  border-radius: 6px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.200912);
  opacity: 1 !important;
  background-color: rgba(245, 245, 245, 1);
  text-align: left;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  line-height: 20px !important;
  letter-spacing: 0px;
}
.drag-drop-empty-option {
  width: 100%;
  height: 6vh;
}
.cursor-grab {
  cursor: grab;
}
.radio-button-container.selected,
.radio-button-container {
  border: 0.818182px solid rgba(27, 58, 148, 1);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  width: 18px !important;
  height: 18px !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.radio-button-container.selected {
  border: 0.818182px solid rgba(195, 108, 230, 1) !important;
}
.radio-button-container.correct {
  border: 0.818182px solid #87ca1b !important;
}
.radio-button-container.incorrect {
  border: 0.818182px solid #df2231 !important;
}

.radio-button {
  opacity: 1;
  width: 14px !important;
  height: 14px !important;
  border-radius: 50% !important;
}
.radio-button.selected {
  background-color: #c36ce6 !important;
}
.radio-button.correct {
  background-color: #87ca1b !important;
}
.radio-button.incorrect {
  background-color: #df2231 !important;
}

.patient-report-preview-container {
  width: fit-content;
  height: fit-content;
  position: relative;
}

.expand-icon {
  position: absolute;
  bottom: -10px;
  right: 10px;
}
@keyframes grow-shrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes unzip {
  0% {
    width: 0;
  }
  100% {
    width: 120%;
    transform: rotate(0deg);
  }
}
@keyframes unzip-line {
  0% {
    transform-origin: top;
    transform: scaleY(0);
  }
  100% {
    transform-origin: top;
    transform: scaleY(1);
  }
}

.zip-click-animation {
  animation: grow-shrink 2s ease-in-out infinite;
}
.unzip-animation {
  animation: unzip 2s ease-in-out;
  animation-fill-mode: forwards;
}
.unzip-line-animation {
  animation: unzip-line 1s ease-in-out;
}

.vertical-line-dashed {
  border: 1px dashed rgba(0, 0, 0, 1);
  opacity: 1;
  width: 1px;
  height: 6vh;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.delayed-div {
  animation: appear 1s ease-in-out forwards;
  opacity: 0;
}

.circle {
  border-radius: 50% !important;
}

.checkbox {
  border: 3px solid rgba(209, 89, 243, 1);
  opacity: 1;
  width: 3.5vh !important;
  height: 3.5vh !important;
  background-color: white;
}
.checkbox.checked {
  background-color: rgba(209, 89, 243, 1);
  background-image: url(./assets/tick.svg) !important;
  background-position: center;
  background-size: 130% auto;
}
.checkbox.correct {
  background-color: #87ca1b !important;
  border: 3px solid #87ca1b !important;
  background-image: url(./assets/tick.svg) !important;
  background-position: center;
  background-size: 130% auto;
}
.checkbox.incorrect {
  border: 3px solid #df2231 !important;
  background-color: #df2231 !important;
  background-image: url(./assets/tick.svg) !important;
  background-position: center;
  background-size: 130% auto;
}

.swal2-actions {
  /*margin-top: 2rem !important*/
}

.bg-confeti {
  background-image: url("./assets/confeti.png");
  background-repeat: no-repeat;
  background-position: bottom -60px center;
  background-size: 120%;
  height: 100%;
}

.font-chalkboard {
  font-family: ChalkboardRegular !important;
}

.bg-enhorabuena {
  background-image: url("./assets/enhorabuena.png");
  background-repeat: no-repeat;
  background-position: left -130px center;
  background-size: 120%;
  height: 100%;
}

.bg-results {
  border-radius: 21px 0 0 21px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  padding-right: 9rem !important;
}

.separator-line {
  opacity: 1;
  background-color: rgba(27, 58, 148, 1);
  height: 4px !important;
  width: 15% !important;
}

.bg-purple {
  position: absolute;
  opacity: 1;
  background-color: rgba(146, 106, 160, 1);
  height: 30% !important;
  width: 100% !important;
  left: 0px !important;
  bottom: 7% !important;
}

.bg-purple-caso {
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(195, 108, 230, 1) !important;
  width: 98% !important;
  height: fit-content !important;
}

.image-carousel-container {
  position: relative;
  background-color: black;
  border-radius: 10px;
}
